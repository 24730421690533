var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text_input"},[_c('label',{staticClass:"font-20 font-w-500",attrs:{"for":_vm.label}},[_vm._v(_vm._s(_vm.label))]),_c('v-text-field',{staticClass:"pa-0",attrs:{"value":_vm.model,"color":_vm.color,"dense":"","solo":"","type":_vm.type,"placeholder":_vm.placeholder,"rules":_vm.required
        ? [
            (v) => !!v || _vm.errorMessage + ' is required',
            _vm.type === 'email'
              ? (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
              : '',
          ]
        : [],"required":"","hide-details":!_vm.required},on:{"input":function($event){return _vm.$emit('update-data', $event)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }